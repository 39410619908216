angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/salesdashboard-equity/:dealId', {
      templateUrl: 'salesDashboardEquityDetails.html'
    });
  })
  .controller('SalesDashboardEquityDetailController', function($scope, $routeParams, gAnalytics, salesClientDeal) {
    gAnalytics.viewPage('/salesdahsboard-equity-detail', 'Detail deal Equity');
    $scope.logDlDoc = function(button) {
      gAnalytics.click(button);
    };

    $scope.dealData = salesClientDeal.get({
      dealId: $routeParams.dealId
    });
  });
