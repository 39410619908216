var layoutName = 'mainLayout';
angular.module('app')
  .config(function(ezLayoutProvider) {
    ezLayoutProvider.register(layoutName, {templateUrl: 'main.html'});
    ezLayoutProvider.setDefault(layoutName);
  })
  .controller('MainLayoutController', function($scope, $location, $rootScope, ezAppScope, ezUsers,
    appInformation, salesClients, supportUsers) {

    $scope.layoutHeader = {templateUrl: '../common/html/header.html'};
    $scope.infoCookies = {templateUrl: '../common/html/infoCookies.html'};
    $scope.layoutLogin = {templateUrl: '../common/html/login.html'};
    // Retrieve alert information
    $scope.alertInformation = appInformation.get();

    // Retrieve contact info for displaying menu and routing (see menubar.js)
    var listener = $scope.$watch('ezweb.user.connected', function(connected) {
      if (connected) {
        listener();
        supportUsers.getInfo(function(infos) {
          $rootScope.$broadcast('NavigateAsInfo', infos, $scope.ezweb.user.roles);
        });
      }
    });

    $scope.stopNavigateAs = function() {
      supportUsers.deselect(function() {
        window.location.reload();
      });
    };

    $scope.logout = function() {
      delete ezAppScope.client;
      salesClients.resetCache();
      ezUsers.logout();
    };
  })
  .controller('HeaderController', function($scope, $i18next, $location, ezUsers, ezLocalStorage, appLanguages) {
    this.navigateAsAttribute = ezUsers.roles.indexOf('CONSULT_OTHER_USER_PERIMETER') > -1 ? 'true' : 'false';

    this.availableValues = appLanguages.availableValues;
    this.currentLanguage = $i18next.options.lng;

    // current environment
    var envName = window.sessionStorage.getItem('environment');
    this.environmentName = envName === 'production' ? undefined : envName;


    var self = this;
    this.changeLanguage = function($event) {
      var selectedLng = $event.detail.language;

      if(self.currentLanguage.toUpperCase() !== selectedLng.toUpperCase()) {

        Object.keys(self.availableValues).forEach(function(key) {

          if (selectedLng.toUpperCase() === self.availableValues[key].toUpperCase()) {
            self.currentLanguage = key;
            ezLocalStorage.language = key;
            $location.path('/');
            window.location.reload();
            return;
          }
        });

      }
    };

    this.init = function() {
      var accountCenter = document.querySelector('#my-account-center');
      if(accountCenter && this.navigateAsAttribute) {
        accountCenter.setAttribute("navigate-as", this.navigateAsAttribute)
      }
    }

    this.startNavigateAs = function() {
      $location.path('/support');
    };
  });
