angular.module('app')
  .controller('SelectLanguageController', function($scope, $i18next, appLanguages, ezAppScope) {
    // Init language select box
    $scope.language = {
      select: appLanguages.valueToUseFromLngDetected($i18next.options.lng),
      options: appLanguages.availableValues
    };

    // When $i18next.options.lng has not been set with localStorage value, it is undefined when loading
    // so we must put $scope.language.select value when i18next says 'i18nextLanguageChange'.
    $scope.$on('i18nextLanguageChange', function(event, language) {
      if ($scope.language.select === undefined) {
        // New initialization must be in evalAsync to see select box change on screen.
        $scope.$evalAsync(function() {
          // Must detect value to avoid 'zh-hk' being redirect to 'en' instead of 'zh'
          $scope.language.select = appLanguages.valueToUseFromLngDetected(language);
        });
      }
    });

    $scope.select = function(selectedLanguage) {
      if (angular.isDefined(selectedLanguage)) {
        if (selectedLanguage !== appLanguages.valueToUseFromLngDetected($i18next.options.lng)) {
          var detectedLanguage = appLanguages.valueToUseFromLngDetected(selectedLanguage);
          $scope.language.select = detectedLanguage;
          // Make the selected language available in appScope
          ezAppScope.ensure('language', {
            select: detectedLanguage
          });
          // Then change the language
          $i18next.options.lng = detectedLanguage;
        }
      }
    };
  });
