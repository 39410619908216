angular.module('app')
  .constant('appLanguages', {
    // Store all available languages
    availableValues: {
      'en': 'EN',
      'fr': 'FR'
      //'zh': '中文 (中国)',
      //'zh-tw': '中文 (台灣)',
      //'ja': '日本語',
      //'ko': '한국어'
    },

    // Function which choose a value in appLanguages.availableValues from an input
    valueToUseFromLngDetected: function(detectedLanguage) {
      if (detectedLanguage === undefined) {
        return undefined;
      }
      if (detectedLanguage in this.availableValues) {
        return detectedLanguage;
      }
      if (detectedLanguage.indexOf('-') >= 0) {
        var baseLanguage = detectedLanguage.split('-')[0];
        if (baseLanguage in this.availableValues) {
          return baseLanguage;
        }
      }
      return 'en';
    }
  })
  .config(function($i18nextProvider, ezLocalStorageProvider, appLanguages) {
    // Get the language preferred by the user in localStorage (if exists) before apps running
    var previouslgValue = ezLocalStorageProvider.localStorage.language;
    // console.log('previous Stored Language:', previouslgValue);
    if (previouslgValue in appLanguages.availableValues) {
      $i18nextProvider.options.lng = previouslgValue;
    }
  })
  .run(function($rootScope, $i18next, appLanguages, ezAppScope, ezLocalStorage) {
    // Listen language changes to store it in localStorage at $i18next initialization
    $rootScope.$on('i18nextLanguageChange', function(event, language) {
      var calculatedLng = appLanguages.valueToUseFromLngDetected(language);
      // console.log('init i18next at:', calculatedLng);
      if ($i18next.options.lng === undefined) {
        $i18next.options.lng = calculatedLng;
      }
      ezAppScope.ensure('language', {
        select: calculatedLng
      });
      ezLocalStorage.language = calculatedLng;
    });
  })
  .directive('selectLanguage', function() {
    return {
      restrict: 'A',
      templateUrl: 'selectLanguage.html',
      replace: false,
      controllerAs: 'langCtrl',
      controller: function($scope, $i18next, appLanguages, ezLocalStorage) {
        // Init language select box
        this.language = {
          select: $i18next.options.lng,
          options: appLanguages.availableValues
        };
        // Reload page when user change
        this.select = function(selectedLanguage) {
          if (angular.isDefined(selectedLanguage)) {
            if (selectedLanguage !== appLanguages.valueToUseFromLngDetected($i18next.options.lng)) {
              var detectedLanguage = appLanguages.valueToUseFromLngDetected(selectedLanguage);
              ezLocalStorage.language = detectedLanguage;
              window.location.reload();
            }
          }
        };
      }
    };
  });
