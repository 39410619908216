angular.module('app')
  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/salesdashboard-equity', {
        templateUrl: 'salesDashboardEquity.html'
      });
    }
  ])
  .filter('sumMtm', function() {
    return function(input) {
      var sum = 0;
      for (var i = 0; i < input.length; i++) {
        sum += input[i].value;
      }
      return sum;
    };
  })
  .controller('SalesDashboardEquityController', function($scope, $window, $location, $filter,
          salesClients, salesClientDeals) {
    // init variables
    $scope.dealsData = {
      clientQueryResolved: false,
      currencies: [{name: 'USD'}, {name: 'EUR'}],
      currenciesQueryResolved: true,
      deals: [],
      dealsQueryResolved: false
    };
    $scope.selectedAll = false;

    // function to extract trade ids from clients
    var extractTradeIds = function(salesClients) {
      var resultIds = [];
      salesClients.forEach(function(salesClient) {
        if (salesClient.checked) {
          resultIds = resultIds.concat(salesClient.tradeIds);
        }
      });
      return resultIds;
    };

    // clients retrieved from scope app or from the service
    var clientsFromStorage = salesClients.getCache();
    if (clientsFromStorage) {
      $scope.dealsData.clientQueryResolved = true;
      $scope.salesClients = clientsFromStorage;
      $scope.selectedAll = $scope.salesClients.filter(function(client) {
        return client.checked;
      }).length === $scope.salesClients.length;
      var tradesId = extractTradeIds($scope.salesClients);
      salesClientDeals.findSalesClientsDealsList({
        toCurrency: $scope.app.currency !== undefined && $scope.app.currency !== null ? $scope.app.currency.name : null,
        listOftradeIds: tradesId
      }, function(equityDeals) {
        $scope.dealsData.deals = equityDeals;
        $scope.dealsData.dealsQueryResolved = true;
      });
    } else {
      salesClients.query(function(result) {
        $scope.salesClients = result;
        $scope.dealsData.clientQueryResolved = true;
        $scope.dealsData.dealsQueryResolved = true;
      });
    }

    // filter unchecked item and no matching item with search filter
    $scope.customFilterForClient = function(item) {
      if (!$scope.searchFilter || $scope.searchFilter.length === 0) {
        // Cas du filtre vide
        return true;
      }
      $scope.searchFilter = $scope.searchFilter.toUpperCase();
      if (item.name.indexOf($scope.searchFilter) > -1) {
        return true;
      }
      return false;
    };

    // check all
    $scope.checkAll = function() {
      angular.forEach($scope.salesClients, function(client) {
        client.checked = $scope.selectedAll;
      });
    };

    // retrieve deals from BCS based on trade ids from selected clients
    $scope.fetchDeals = function() {
      var tradesId = extractTradeIds($scope.salesClients);
      $scope.dealsData.dealsQueryResolved = false;

      salesClientDeals.findSalesClientsDealsList({
        toCurrency: $scope.app.currency !== undefined && $scope.app.currency !== null ? $scope.app.currency.name : null,
        listOftradeIds: tradesId
      }, function(equityDeals) {
        $scope.dealsData.deals = equityDeals;
        $scope.dealsData.dealsQueryResolved = true;
      });
    };

    // callback from equity directive to open details for a deal
    $scope.openDeal = function(tradeId) {
      $location.path('/salesdashboard-equity/' + tradeId);
    };

    // callback from equity directive to update currency
    $scope.updateCurrency = function(currency) {
      $scope.dealsData.dealsQueryResolved = false;
      var tradesId = extractTradeIds($scope.salesClients);
      salesClientDeals.findSalesClientsDealsList({
        toCurrency: currency ? currency.name : null,
        listOftradeIds: tradesId
      }, function(equityDeals) {
        $scope.dealsData.deals = equityDeals;
        $scope.dealsData.dealsQueryResolved = true;
      });
    };

    // function used to properly sort the column NotionalAmountQuantity with dataTable
    $scope.sortNotionalAmountQuantity = function(equityData) {
      return (equityData.productTypetoView === 'Option' || equityData.productTypetoView === 'ELS') ?
        equityData.quantity : equityData.notionalAmount.value;
    };

    // function used to properly display the column NotionalAmountQuantity with dataTable
    $scope.displayNotionalAmountQuantity = function(equityData) {
      return (equityData.productTypetoView === 'Option' || equityData.productTypetoView === 'ELS') ?
        ($filter('number')(equityData.quantity, 2)) :
        (($filter('number')(equityData.notionalAmount.value, 2)) + ' ' + equityData.notionalAmount.currency);
    };

    // dataTable callback to save / store table state
    var keyDataTableLength = 'equity_dataTable_length';
    $scope.dataTableLoadState = function(settings) {
      var previousValue = $window.localStorage[keyDataTableLength];
      if (previousValue !== undefined) {
        settings._iDisplayLength = parseInt(previousValue, 10);
      }
      return settings;
    };

    $scope.dataTableSaveState = function(settings, data) {
      if (data !== undefined && data.length !== undefined) {
        $window.localStorage.setItem(keyDataTableLength, data.length);
      }
    };
  });
