angular.module('app')
  .filter('arrayToDate', function() {
    return function(input) {
      if (!angular.isArray(input)) {
        return '';
      }
      return new Date(input[0], input[1] - 1, input[2], 0, 0, 0, 0);
    };
  })
  .filter('arrayToDateTime', function() {
    return function(input) {
      if (!angular.isArray(input)) {
        return '';
      }
      return new Date(input[0], input[1] - 1, input[2], input[3], input[4], 0, 0);
    };
  })
  .filter('arrayToStrDateWithMonth', function($i18next) {
    var tabKey = {
      1: 'ezweb-form:datepicker.monthsShort.Jan',
      2: 'ezweb-form:datepicker.monthsShort.Feb',
      3: 'ezweb-form:datepicker.monthsShort.Mar',
      4: 'ezweb-form:datepicker.monthsShort.Apr',
      5: 'ezweb-form:datepicker.monthsShort.May',
      6: 'ezweb-form:datepicker.monthsShort.Jun',
      7: 'ezweb-form:datepicker.monthsShort.Jul',
      8: 'ezweb-form:datepicker.monthsShort.Aug',
      9: 'ezweb-form:datepicker.monthsShort.Sep',
      10: 'ezweb-form:datepicker.monthsShort.Oct',
      11: 'ezweb-form:datepicker.monthsShort.Nov',
      12: 'ezweb-form:datepicker.monthsShort.Dec'
    };
    return function(input) {
      if (!angular.isArray(input)) {
        return '';
      }
      return input[2] + '-' + $i18next(tabKey[input[1]]) + '-' + input[0];
    };
  });
