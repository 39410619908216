angular.module('app')
  .factory('alphaLinkSrv', function($resource) {
    return {
      productDetails: $resource('mvc/alphaLink/', {}, {}),
      productPerformance: $resource('mvc/alphaLink/performances', {}, {}),
      cAAEvent: $resource('mvc/alphaLink/caApplicationEvent', {}, {}),
      chart: $resource('mvc/alphaLink/chart', {}, {})
    };
  })
  ;
