angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/valuation/equity', {
      templateUrl: 'equityList.html'
    });
  })
  .controller('EquityListController', function($scope, $location, $filter, $window, gAnalytics, valuationService) {
    gAnalytics.viewPage('/equity-list', 'List deal Equity');

    // init dealsData so no null value is passed to the equity directive
    $scope.dealsData = {
      currencies: [],
      currenciesQueryResolved: false,
      deals: [],
      dealsQueryResolved: false
    };

    // init userCurrencies so no null value is passed to the equity directive
    $scope.userCurrencies = valuationService.userCurrencies().query({}, function(dataReceived) {
      $scope.dealsData.currencies = dataReceived;
      $scope.dealsData.currenciesQueryResolved = true;
    });

    // Equity
    // init data grid
    valuationService.deals().query({
      assetId: 'Equity',
      userPreferredCurrency: undefined
    },
    function(dataReceived) {
      $scope.dealsData.deals = dataReceived;
      $scope.dealsData.dealsQueryResolved = true;
    });

    // callback from equity directive to update currency
    $scope.updateCurrency = function(currency) {
      valuationService.deals().query({
        assetId: 'Equity',
        userPreferredCurrency: currency ? currency.name : undefined
      },
      function(dataReceived) {
        $scope.dealsData.deals = dataReceived;
        $scope.dealsData.dealsQueryResolved = true;
      });
    };

    // callback from equity directive to open details for a deal
    $scope.openDeal = function(tradeId) {
      $location.path('/valuation/equity/' + tradeId);
    };

    // function used to properly sort the column NotionalAmountQuantity with dataTable
    $scope.sortNotionalAmountQuantity = function(equityData) {
      return (equityData.productTypetoView === 'Option' || equityData.productTypetoView === 'ELS') ?
        equityData.quantity : equityData.notionalAmount.value;
    };

    // function used to properly display the column NotionalAmountQuantity with dataTable
    $scope.displayNotionalAmountQuantity = function(equityData) {
      return (equityData.productTypetoView === 'Option' || equityData.productTypetoView === 'ELS') ?
        ($filter('number')(equityData.quantity, 2)) :
        (($filter('number')(equityData.notionalAmount.value, 2)) + ' ' + equityData.notionalAmount.currency);
    };
  });
