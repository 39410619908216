angular.module('app')
  .factory('supportUsers', function($http) {
    var search = function(input, callback) {
      $http.get('mvc/contact/findContacts?searchInputContact=' + input).then(function(response) {
        callback(response.data);
      });
    };
    var select = function(selected, callback) {
      $http.get('mvc/contact/selectContact?contactSelected=' + selected).then(function(response) {
        callback(response.data);
      });
    };
    var getInfo = function(callback) {
      $http.get('mvc/contact/contactInformation').then(function(response) {
        callback(response.data);
      });
    };
    var deselect = function(callback) {
      $http.get('mvc/contact/deselectContact').then(function(response) {
        callback(response.status);
      });
    };
    return {
      search: search,
      select: select,
      getInfo: getInfo,
      deselect: deselect
    };
  });
