angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/stp/internalView', {
      templateUrl: 'stpInternalView.html'
    });
  })
  .controller('StpInternalViewController', function($scope, $window, $location, gAnalytics, ezAppScope, stpProducts) {
    gAnalytics.viewPage('/stp/internalView', 'Stp');

    $scope.products = [];
    $scope.loading = false;

    // Set in appScope the search criteria in order to preserve last search criteria.
    var store = function(legalBdrIdOrEliotCode, productCodes) {
      ezAppScope.ensure('stp', {
        legalBdrIdOrEliotCode: legalBdrIdOrEliotCode,
        productCodes: productCodes
      });
    };

    // function to handle id separators
    var handleIdSeparator = function(listOfCodes) {
      if (listOfCodes !== undefined && listOfCodes.length !== undefined) {
        listOfCodes = listOfCodes.replace(/;/g, ',');
        return listOfCodes.replace(/(\s+)/gi, '');
      }
    };

    // Reload and display products with the last persisted search criteria.
    if (angular.isDefined($scope.app.stp) &&
      (angular.isDefined($scope.app.stp.legalBdrIdOrEliotCode) || angular.isDefined($scope.app.stp.productCodes))) {
      if (angular.isDefined($scope.app.stp.legalBdrIdOrEliotCode)) {
        $scope.loading = true;
        $scope.searchInputEc = $scope.app.stp.legalBdrIdOrEliotCode;
        $scope.fetchInputCodes = '';
        stpProducts.loadProducts().query({legalBdrIdOrEliotCode: $scope.searchInputEc}, function(result) {
          $scope.products = result;
          $scope.loading = false;
        });
      } else if (angular.isDefined($scope.app.stp.productCodes)) {
        $scope.loading = true;
        $scope.searchInputEc = '';
        $scope.fetchInputCodes = $scope.app.stp.productCodes;
        var codeToFetch = handleIdSeparator($scope.fetchInputCodes);
        stpProducts.fetchProducts().query({productCodes: codeToFetch}, function(result) {
          $scope.products = result;
          $scope.loading = false;
        });
      }
    }

    // Load Products id from SPARK then fetch products from BCS
    $scope.load = function() {
      $scope.loading = true;
      store($scope.searchInputEc, undefined);
      stpProducts.loadProducts().query({legalBdrIdOrEliotCode: $scope.searchInputEc}, function(result) {
        $scope.products = result;
        $scope.loading = false;
      });
    };

    // Fetch product from BCS
    $scope.fetch = function() {
      $scope.loading = true;
      store(undefined, $scope.fetchInputCodes);
      var codeToFetch = handleIdSeparator($scope.fetchInputCodes);
      stpProducts.fetchProducts().query({productCodes: codeToFetch}, function(result) {
        $scope.products = result;
        $scope.loading = false;
      });
    };

    $scope.openProduct = function(product) {
      var pathToProductDetails = '/stp/' + product.productId.source +
        (product.productId.type ? '/' + product.productId.type + '/' : '/') + product.productId.code;
      $location.path(pathToProductDetails);
    };

    // Separate the new pasted value in this field by ','
    var idsPattern = /[\w\d-]+/g;
    $scope.handlePaste = function(event) {
      var pasteValue = event.clipboardData.getData('text/plain');
      // replace delimiter with ';'
      var tabOfId = pasteValue.match(idsPattern);
      $scope.fetchInputCodes = tabOfId.join(';');
      event.preventDefault();
    };
  });
