angular.module('app')
  .controller('LoginController', function($rootScope, $location) {
    if ($rootScope.app.authentification.type === 'SSO' && !$rootScope.ezweb.user.connected) {
      // When page refresh (F5) $scope.ezweb.user maybe not be finished to be filled immediatly so ask it.
      $rootScope.ezweb.user.get(function(user) {
        if (!user.connected) {
          $rootScope.myUserNotConnected = true;
          $location.path('/home');
        }
      });
      // But when the user really not connected and force an url...
      if ($rootScope.myUserNotConnected) {
        $location.path('/home');
      }
    }

    $rootScope.$on('ezweb.logout', function() {
      $location.path('/home');
    });
  });
