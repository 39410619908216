angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/profile', {
      templateUrl: 'profile.html'
    });
  })
  .controller('ProfileController', function($scope, gAnalytics, profileCurrent, profileChangePwdUrl) {
    gAnalytics.viewPage('/profile', 'Profile');

    $scope.currentUser = profileCurrent.get();
    $scope.changePwdUrl = profileChangePwdUrl.get();
  });
