angular.module('app')
  .controller('InfoCookiesController', function($scope, $window) {
    $scope.acceptCookiesInfo = function(acceptation) {
      if (acceptation) {
        $window.localStorage.removeItem('cookiesNotAccepted');
      } else {
        $window.localStorage.cookiesNotAccepted = true;
      }
      angular.element('#cookiealert_container').addClass('hide');
      $window.localStorage.hideCookiesInfoDate = new Date().getTime();
    };

    var hideCookiesInfoDate = parseInt($window.localStorage.hideCookiesInfoDate, 10);
    if (new Date().getTime() - 1000 * 24 * 60 * 60 * 1000 - hideCookiesInfoDate < 0) {
      angular.element('#cookiealert_container').addClass('hide');
    } else {
      $window.localStorage.removeItem('cookiesNotAccepted');
    }
  });
