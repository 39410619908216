angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/valuation/cty', {
      templateUrl: 'ctyList.html'
    });
  })
  .controller('CtyListController', function($scope, $location, $window, gAnalytics, valuationService) {
    gAnalytics.viewPage('/cty-list', 'List deal Cty');
    $scope.logDlDoc = function(button) {
      gAnalytics.click(button);
    };

    $scope.userCurrencies = valuationService.userCurrencies().query();

    // CTY
    $scope.$watch('userCurrencyDealCty', function(currency) {
      $scope.dealCtyDatas = valuationService.deals().query({
        assetId: 'Cty',
        userPreferredCurrency: currency ? currency.name : undefined
      });
    });

    $scope.openDealCty = function(deal) {
      $location.path('/valuation/cty/' + deal.tradeId);
    };

    // dataTable callback to save / store table state
    var keyDataTableLength = 'cty_dataTable_length';
    $scope.dataTableLoadState = function(settings) {
      var previousValue = $window.localStorage[keyDataTableLength];
      if (previousValue !== undefined) {
        settings._iDisplayLength = parseInt(previousValue, 10);
      }
      return settings;
    };
    $scope.dataTableSaveState = function(settings, data) {
      if (data !== undefined && data.length !== undefined) {
        $window.localStorage.setItem(keyDataTableLength, data.length);
      }
    };
  });
