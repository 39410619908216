angular.module('app')
  .factory('productSubscriptions', function($resource) {
    return $resource('mvc/products/listSubscriptions', {}, {});
  })
  .factory('productsList', function($resource) {
    return $resource('mvc/products/listProductsBySubscriptionId/:subscriptionId', {}, {});
  })
  // jscs:disable maximumLineLength
  .factory('productsDescription', function($resource) {
    return $resource('mvc/products/getProductBySubscriptionIdAndProductCode/:subscriptionId/:productSource/:productType/:productCode', {}, {});
  })
  .factory('productsPerformances', function($resource) {
    return $resource('mvc/products/getPerformancesBySubscriptionIdAndProductCode/:subscriptionId/:productSource/:productType/:productCode', {}, {});
  })
  .factory('productsPerfReport', function($resource) {
    return $resource('mvc/products/listPerfReportsForProductCode/:subscriptionId/:productSource/:productType/:productCode', {}, {});
  })
  .factory('productsHistoPerfReport', function($resource) {
    return $resource('mvc/products/listHistoricalPerfReportsForProductCode/:productSource/:productType/:productCode', {}, {});
  })
  .factory('productsCAAEvent', function($resource) {
    return $resource('mvc/products/listCaApplicationsForProductCode/:productSource/:productType/:productCode', {}, {});
  })
  .factory('productsChart', function($resource) {
    return $resource('mvc/products/getChartBySubscriptionIdAndProductCode/:subscriptionId/:productSource/:productType/:productCode', {}, {});
  })
  .factory('productsUnderlyingPrices', function($resource) {
    return $resource('mvc/products/listUnderlyingPricesForProductCode/:subscriptionId/:productSource/:productType/:productCode', {}, {});
  })
  // jscs:enable maximumLineLength
  .factory('productsHistoPrices', function($resource) {
    return $resource('mvc/products/getHistoPrices', {}, {});
  });
