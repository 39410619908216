angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/valuation/ird/:dealId', {
      templateUrl: 'irdDetail.html'
    });
  })
  .controller('IrdDetailController', function($scope, $routeParams, gAnalytics, valuationService, crossAmountService) {
    gAnalytics.viewPage('/ird-detail', 'Detail deal IRD');
    $scope.logDlDoc = function(button) {
      gAnalytics.click(button);
    };

    $scope.userCurrencies = valuationService.userCurrencies().query();

    $scope.$watch('userCurrencyDealIrd', function(currency) {
      if (currency !== null && currency !== undefined && $scope.dealData !== null && $scope.dealData !== undefined &&//
        currency.name !== $scope.dealData.mtm.currency) {
        var crossAmountVO = {};
        crossAmountVO.initialAmount = $scope.dealData.mtm.value;
        crossAmountVO.fromCurrency = $scope.dealData.mtm.currency;
        crossAmountVO.toCurrency = currency.name;
        crossAmountVO.quotationDate = $scope.dealData.valuationDate;
        crossAmountVO.productType = 'Ird';

        crossAmountService.findCrossAmount(crossAmountVO, function(data) {
          if (data !== null && data !== undefined && //
            data.value !== null && data.currency !== null && //
            data.value !== undefined && data.currency !== undefined) {
            $scope.dealData.mtm.value = data.value;
            $scope.dealData.mtm.currency = data.currency;
          }
        });
      }
    });

    $scope.dealData = valuationService.deals().get({
      assetId: 'Ird',
      dealId: $routeParams.dealId
    });
  });
