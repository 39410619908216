angular.module('app').controller('DfaDisclaimer', function (supportUsers, $location, $scope, $uibModalInstance) {
  var pc = this;
  
  function changeLocation() {
    supportUsers.getInfo(function(infos) {
      var assets = infos.assets;
      if ($scope.ezweb.user.roles.indexOf('CONSULT_SALES_DASHBOARD') > -1) {
        $location.path('/salesdashboard-equity');
      } else if (assets && assets[0]) {
        $location.path('/valuation/' + assets[0]);
      } else {
        $location.path('/dashboard');
      }
    });
  }

  pc.validateDfaDisclaimer = function() {
    $scope.ezweb.user.dfaDisclaimerOpened = false;
    $uibModalInstance.close();
    changeLocation();
  };
});