angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/admin', {
      templateUrl: 'admin.html'
    });
  })
  .controller('AdminController', function($scope, gAnalytics, ezAppScope, adminUsers, adminGroups, $location) {
    gAnalytics.viewPage('/admin', 'Admin');
    $scope.logDlDoc = function(button) {
      gAnalytics.click(button);
    };

    $scope.searchInputUser = '';
    $scope.usersData = [];

    $scope.editUser = function(userName) {
      $location.path('/admin/editUser').search('user', userName);
    };

    // edit group
    $scope.editGroup = function(group) {
      ezAppScope.ensure('group', {
        editedGroup: group
      });
      $location.path('/admin/editGroup');
    };

    // search method
    $scope.search = function() {
      $scope.usersData = adminUsers.query({
        searchInputUser: $scope.searchInputUser
      });
    };

    // get all groups
    $scope.groupsData = adminGroups.query();
  });
