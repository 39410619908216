angular.module('app')
  .directive('menubar', function() {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'menubar.html',
      scope: true,
      controller: function($scope) {
        $scope.menuList = null;
        $scope.hasAdmMenu = false;
        $scope.hasStpView = false;
        $scope.hasStpMyPortfolioOnly = false;

        $scope.$on('NavigateAsInfo', function(event, contactInfo, roles) {
          var menuList = []; // menuList must be an array if we want to use 'limitTo' in ng-repeat
          var index = 0;

          if (contactInfo.assets && roles.indexOf('CONSULT_VALUATION_DATA') > -1) {
            for (index = 0; index < contactInfo.assets.length; index++) {
              var asset = contactInfo.assets[index];
              menuList[index] = {
                route: '/valuation/' + asset + '/*.*',
                trad: 'common:navBar.' + asset,
                href: '#/valuation/' + asset
              };
            }
          }

          if (roles.indexOf('CONSULT_SALES_DASHBOARD') > -1) {
            menuList[index] = {
              route: '/salesdashboard-equity',
              trad: 'common:navBar.salesdashboard',
              href: '#/salesdashboard-equity'
            };
            index++;
          }

          if (contactInfo.withDocuments && roles.indexOf('CONSULT_DOCUMENTS') > -1) {
            menuList[index] = {
              route: '/documents',
              trad: 'common:navBar.documents',
              href: '#/documents'
            };
          }

          if (roles.indexOf('CONSULT_EDIT_USERS') > -1 || roles.indexOf('CONSULT_EDIT_GROUPS') > -1 || roles.indexOf(
              'MANAGE_INFORMATION_MESSAGE') > -1) {
            $scope.hasAdmMenu = true;
          } else {
            $scope.hasAdmMenu = false;
          }

          if (roles.indexOf('CONSULT_STP_PRODUCTS') > -1 || roles.indexOf('CONSULT_STP_SALE_PRODUCTS') > -1) {
            $scope.hasStpView = true;
            if (roles.indexOf('CONSULT_STP_PRODUCTS') === -1) {
              $scope.hasStpMyPortfolioOnly = true;
            }
          }

          $scope.menuList = menuList;
        });

        $scope.$watch('ezweb.responsive.breakpoint', function(breakpoint) {
          if (breakpoint === 'sm') {
            $scope.maxMenutoDisplay = 2;
          } else if (breakpoint === 'md') {
            $scope.maxMenutoDisplay = 4;
          } else {
            $scope.maxMenutoDisplay = 6;
          }
        });
      }
    };
  });
