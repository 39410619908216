angular.module('app')
  .directive('omegaHistoPrdChart', function($templateCache, $i18next) {
    return {
      restrict: 'E',
      templateUrl: 'omegaHistoPrdChart.html',
      scope: {
        data: '='
      },
      link: function($scope, $element) {
        var listener = $scope.$watch('data.$resolved', function(resolved) {
          if (resolved) {
            listener(); // $watch returns a deregistration function. Calling it would deregister the $watcher

            var graphicStaticOptions = JSON.parse($templateCache.get('histoGraphOptions'));

            var locale = 'lang_' + $i18next.options.lng;
            if (locale in graphicStaticOptions.globalOptions) {
              graphicStaticOptions.globalOptions.lang = graphicStaticOptions.globalOptions[locale];
            }

            if (window.Highcharts) {
              var graphHighcharts = window.Highcharts;
              graphHighcharts.setOptions(graphicStaticOptions.globalOptions);
              var highChartConfig = graphicStaticOptions.options;
              highChartConfig.chart.renderTo = $element.children()[1];

              highChartConfig.series = $scope.data.options ? $scope.data.options.series : null || null;

              if (highChartConfig.rangeSelector === undefined) {
                highChartConfig.rangeSelector = {};
              }
              highChartConfig.rangeSelector.inputEnabled = $scope.$parent.ezweb.responsive.breakpoint !== 'xs';

              if (highChartConfig.series !== null) {
                highChartConfig.series[0].animation = false;
                highChartConfig.series[0].dataGrouping = {
                  enabled: true,
                  groupPixelWidth: 6
                };
                var chart = new graphHighcharts.StockChart(highChartConfig);

                $scope.$on('$destroy', function() {
                  if (chart) {
                    chart.destroy();
                  }
                  $element.children().remove();
                });
              }
            }
          }
        });
      }
    };
  });
