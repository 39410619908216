angular.module('app')
  .factory('stpProducts', function($resource) {
    return {
      loadProducts: function() {
        return $resource('mvc/stp/products/:legalBdrIdOrEliotCode', {}, {});
      },
      fetchProducts: function() {
        return $resource('mvc/stp/products/:productCodes/fetchBcs', {}, {});
      },
      loadSaleProducts: function() {
        return $resource('mvc/stp/saleProducts', {}, {});
      },
      productDetails: $resource('mvc/stp/product/:source/:type/:code', {}, {}),
      productPerformance: $resource('mvc/stp/performance/:source/:type/:code', {}, {}),
      cAAEvent: $resource('mvc/stp/caApplicationEvent/:source/:type/:code', {}, {}),
      loadProductsUnderlyingPrices: $resource('mvc/stp/listUnderlyingPrices', {}, {}),
      chart: $resource('mvc/stp/getChart', {}, {})
    };
  })
  ;
