angular.module('app')
  .factory('gAnalytics', function($window, $i18next) {
    function canSendAnalytics() {
      if ($window.localStorage.cookiesNotAccepted) {
        return false;
      }
      if (typeof $window.ga !== 'function' || typeof $window.ga.getAll !== 'function' || !$window.app.gaAccount) {
        return false;
      }
      if ($window.ga.getAll().length === 0) {
        $window.ga('create', $window.app.gaAccount, 'auto');
      }
      return true;
    }

    var service = {
      viewPage: function(page, title) {
        if (canSendAnalytics()) {
          $window.ga('set', 'language', $i18next.options.lng);
          $window.ga('send', 'pageview', {page: page, title: title});
        }
      },
      click: function(event) {
        if (canSendAnalytics()) {
          $window.ga('send', 'event', 'click', event, {useBeacon: true});
        }
      }
    };
    return service;
  });
