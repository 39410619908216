angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/stp/myPortfolio', {
      templateUrl: 'stpMyPortFolio.html'
    });
  })
  .controller('StpMyPortFolioController', function($scope, $window, $location, gAnalytics, ezAppScope, stpProducts) {
    gAnalytics.viewPage('/stp/myPortFolio', 'Stp');

    // load Sale products
    $scope.saleProducts = stpProducts.loadSaleProducts().query();

    $scope.openProduct = function(product) {
      var pathToProductDetails = '/stp/' + product.productId.source +
        (product.productId.type ? '/' + product.productId.type + '/' : '/') + product.productId.code;
      $location.path(pathToProductDetails);
    };
  });
