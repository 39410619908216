angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/admin/editGroup', {
      templateUrl: 'manageGroup.html'
    });
  })
  .controller('ManageGroupController', function($scope, $location, gAnalytics, ezAppScope, adminGroups,
    adminRemoveGroup) {
    gAnalytics.viewPage('/admin/editGroup', 'Manage group');

    $scope.rolesData = ['ADMINISTRATOR',
      'MANAGE_INFORMATION_MESSAGE',
      'CONSULT_EDIT_USERS',
      'CONSULT_EDIT_GROUPS',
      'CONSULT_OTHER_USER_PERIMETER',
      'CONSULT_VALUATION_DATA',
      'CONSULT_MATCH_TRADES',
      'CONSULT_SETTLEMENTS',
      'CONSULT_SERVICES',
      'CONSULT_DOCUMENTS',
      'CONSULT_DASHBOARD',
      'CONSULT_SALES_DASHBOARD',
      'CONSULT_SALES',
      'CONSULT_STP_PRODUCTS',
      'CONSULT_STP_SALE_PRODUCTS',
      'CONSULT_MONITORING_DATA'
    ];

    // if a group doesn't exist then fields will be empty
    if ($scope.app.group === undefined || $scope.app.group.editedGroup === undefined) {
      $scope.group = {
        name: '',
        desc: '',
        role: {}
      };
      $scope.isCreation = true;
    } else {
      // if the group exist then we display group data (name, desc and roles)
      var roles = {};
      $scope.app.group.editedGroup.roles.forEach(function(val) {
        roles[val] = true;
      });
      $scope.group = {
        name: $scope.app.group.editedGroup.name,
        desc: $scope.app.group.editedGroup.description,
        role: roles
      };
      $scope.isCreation = false;
    }

    // create or update a group
    $scope.createOrUpdate = function() {
      adminGroups.save($scope.group,
        function() {
          $scope.isSaveOk = true;
          $scope.saveMessage = $scope.isCreation ? 'admin:messageCreateGroupInfo' : 'admin:messageEditGroupInfo';
        },
        function() {
          $scope.isSaveOk = false;
          $scope.saveMessage = $scope.isCreation ? 'admin:messageCreateGroupError' : 'admin:messageEditGroupError';
        }
      );
    };

    // remove a group
    $scope.removeCurrentGroup = function() {
      if ($scope.app.group && $scope.app.group.editedGroup) {
        adminRemoveGroup.get({groupName: $scope.group.name},
          function() {
            $location.path('/admin');
          },
          function() {
            $scope.isSaveOk = false;
            $scope.saveMessage = 'admin:messageRemoveGroupError';
          });
      }
    };
  });
