angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/alphaLink/', {
      templateUrl: 'alphaLink.html'
    });
  })
  .controller('AlphaLinkController', function($scope, $routeParams, gAnalytics, alphaLinkSrv) {
    gAnalytics.viewPage('/alphaLink', 'alphaLink');
    var self = this;
    var token = $routeParams.token;
    self.error = false;

    self.productData = alphaLinkSrv.productDetails.get({token: token}, function success(prdData) {
      if (!prdData.productId) {
        self.error = true;
        return;
      }
      if (prdData.withPerformances) {
        self.productPerfs = alphaLinkSrv.productPerformance.query();
      }
      if (prdData.withGraphic) {
        self.histoGraphData = alphaLinkSrv.chart.get();
      }
    });

    self.loadCaa = function() {
      if (self.productData.withCorparateAction && !self.caApplications) {
        self.caApplications = alphaLinkSrv.cAAEvent.query();
      }
    };

    self.loadUnderlyingPrices = function() {
    };
  });
