angular.module('app')
  .directive('stpGrid', function() {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'stpGrid.html',
      scope: {
        productData: '=',
        productCallback: '&'
      },
      controller: function($scope, $window, $location) {
        // open the details of a product
        $scope.openProduct = function(product) {
          // need to pass an object to the callback if not, it does not work
          $scope.productCallback({product: product});
        };

        // ez-grid callback
        var path = $location.path();
        path = path.substr(path.lastIndexOf('/') + 1);
        var keyDataTableLength = 'stp' + path + '_dataTable_length';

        $scope.dataTableLoadState = function(settings) {
          var previousValue = $window.localStorage[keyDataTableLength];
          if (previousValue !== undefined) {
            settings._iDisplayLength = parseInt(previousValue, 10);
          }
          return settings;
        };
        $scope.dataTableSaveState = function(settings, data) {
          if (data !== undefined && data.length !== undefined) {
            $window.localStorage.setItem(keyDataTableLength, data.length);
          }
        };
      }
    };
  });
