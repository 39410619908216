angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/valuation/creder', {
      templateUrl: 'crederList.html'
    });
  })
  .controller('CrederListController', function($scope, $location, $window, gAnalytics, valuationService) {
    gAnalytics.viewPage('/creder-list', 'List deal Creder');
    $scope.logDlDoc = function(button) {
      gAnalytics.click(button);
    };

    $scope.userCurrencies = valuationService.userCurrencies().query();

    // creder
    $scope.$watch('userCurrencyDealCreder', function(currency) {
      $scope.dealCrederDatas = valuationService.deals().query({
        assetId: 'Creder',
        userPreferredCurrency: currency ? currency.name : undefined
      });
    });

    $scope.openDealCreder = function(deal) {
      $location.path('/valuation/creder/' + deal.tradeId);
    };

    // dataTable callback to save / store table state
    var keyDataTableLength = 'creder_dataTable_length';
    $scope.dataTableLoadState = function(settings) {
      var previousValue = $window.localStorage[keyDataTableLength];
      if (previousValue !== undefined) {
        settings._iDisplayLength = parseInt(previousValue, 10);
      }
      return settings;
    };
    $scope.dataTableSaveState = function(settings, data) {
      if (data !== undefined && data.length !== undefined) {
        $window.localStorage.setItem(keyDataTableLength, data.length);
      }
    };
  });
