angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/valuation/sol/:subscriptionId/:productSource/:productType/:productCode', {
      templateUrl: 'solDetail.html'
    }).when('/valuation/sol/:subscriptionId/:productSource/:productCode', {
      templateUrl: 'solDetail.html'
    });
  })
  .filter('customOrI18next', function($i18next) {
    return function(string, custom) {
      return custom ? custom : $i18next(string);
    };
  })
  .controller('SolDetailController', function($scope, $routeParams, $location, ezAppScope, gAnalytics,
    productsDescription, productsPerfReport, productsCAAEvent, productsUnderlyingPrices, productsHistoPrices,
    productsHistoPerfReport, productsChart) {
    gAnalytics.viewPage('/sol-detail', 'Product detail');
    $scope.logDlDoc = function(button) {
      gAnalytics.click(button);
    };

    $scope.subscriptionId = $routeParams.subscriptionId;
    // Set in appScope the "subscriptionId" to display current subscription view when user go back to "solList" screen.
    ezAppScope.ensure('sol', {
      subscriptionId: $scope.subscriptionId
    });

    var queryObj = {
      subscriptionId: $routeParams.subscriptionId,
      productSource: $routeParams.productSource,
      productType: $routeParams.productType,
      productCode: $routeParams.productCode
    };

    $scope.productData = productsDescription.get(queryObj, function(productData) {
      if (productData.observationEvents) {
        productData.observationEvents.forEach(function(observationEvent) {
           // retrieve the newest event and store its observation date into producData.finalObservationDate
          if (observationEvent.observationDate &&
             (!productData.finalObservationDate || observationEvent.observationDate.join() > productData.finalObservationDate.join())) {
            productData.finalObservationDate = observationEvent.observationDate;
          }
        });
      }
      if (productData.withTermSheet || productData.withDocuments) {
        $scope.productPerfReport = productsPerfReport.get(queryObj);
      }
      if (productData.translations === undefined) {
        // Simplify var test in html
        productData.translations = {};
      }
    });

    $scope.loadCaa = function() {
      if ($scope.productData.withCorparateAction && !$scope.caApplications) {
        $scope.caApplications = productsCAAEvent.query(queryObj);
      }
    };

    $scope.loadUnderlyingPrices = function() {
      if ($scope.productData.withUnderlyings && !$scope.underlyings) {
        $scope.underlyings = productsUnderlyingPrices.query(queryObj);
      }
      return false;
    };

    $scope.histoGraphData = productsChart.get(queryObj);

    $scope.getHistoPrices = function() {
      $scope.histoPrices = productsHistoPrices.query();
    };

    $scope.getHistoPerfReport = function() {
      if (!$scope.productsHistoPerfReport) {
        if ($scope.productData.withTermSheet || $scope.productData.withDocuments) {
          $scope.productsHistoPerfReport = productsHistoPerfReport.query(queryObj);
        }
      }
    };
  });
