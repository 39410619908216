angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/valuation/sol', {
      templateUrl: 'solList.html'
    });
  })
  .controller('SolListController', function($scope, $location, $timeout, ezAppScope, gAnalytics, productSubscriptions,
    productsList) {
    gAnalytics.viewPage('/sol-list', 'Product list');
    $scope.logDlDoc = function(button) {
      gAnalytics.click(button);
    };

    // function to calculate the default selected subscription id
    var getDefaultsubscription = function(listOfSubscriptions, inputId) {
      if (inputId !== undefined) {
        var inputIdAsNumber = parseInt(inputId, 10);
        var i = listOfSubscriptions.length;
        while (i--) {
          if (listOfSubscriptions[i].subscriptionId === inputIdAsNumber) {
            return listOfSubscriptions[i].subscriptionId;
          }
        }
      }
      return listOfSubscriptions.length > 0 ? listOfSubscriptions[0].subscriptionId : undefined;
    };

    // Retrieve the user subscription list
    $scope.userSolSubscriptions = productSubscriptions.query({}, function(receivedData) {
      // When subscriptions list has been received Then set default selection in selectBox
      receivedData.sort(function(sub1, sub2) {
        return sub1.subscriptionName.localeCompare(sub2.subscriptionName);
      });
      $scope.selectedSolSubscription = getDefaultsubscription(
        receivedData, ezAppScope.sol === undefined ? undefined : ezAppScope.sol.subscriptionId
      );
    });

    // And add a watch to retrieve products from the default selected subscription
    $scope.$watch('selectedSolSubscription', function(subscriptionId) {
      if (subscriptionId) {
        $scope.subscriptionDatas = null;

        productsList.get({
          subscriptionId: subscriptionId
        }, function(subscriptionDatas) {
          // Avoid a bug of empty data
          if (subscriptionDatas.products === undefined) {
            subscriptionDatas.products = [];
          }
          $scope.subscriptionDatas = subscriptionDatas;
        });

        // some hack to help the fully handicapped IE
        $timeout(function() {
          var box = document.getElementById('solSubscriptionSelectBox');
          if (box !== undefined && box.selectedIndex !== undefined && box.options !== undefined) {
            if (box.options[box.selectedIndex].text !== box.options[box.selectedIndex].innerHTML) {
              var text = box.options[box.selectedIndex].innerHTML;
              box.options[box.selectedIndex].text = text.replace(/&amp;/g, '&');
            }
          }
        }, 500, false);
      }
    });
  })
  .directive('omegaSolList', function($compile, $location) {
    /* eslint-disable */
    // Define the variable here to avoid recreate it on each template call
    var tableHeader = '<ez-grid data="productData" value="solData" class="omega-table" dom="<\'row form-inline\'<\'col-md-6\'f><\'col-md-6 hidden-sm\'l>><\'solDataTableScrolled\'t>ip" page-length="20" length-menu="[10, 20, 50, 100]" ez-hide-mobile-header="false" id="solDataTable" auto-width="false" state-save="true" state-save-callback="dataTableSaveState(settings, data)" state-load-callback="dataTableLoadState(settings)">' + '<ez-grid-rows ng-click="openSolProduct(solData)" class="clickable"></ez-grid-rows>';
    var tableFooter = '</ez-grid>';

    var tableOfTemplate = {
      'NAME': '<ez-grid-column ez-i18n-title="valuation:product" class="solListName">{{solData.name}}</ez-grid-column>',
      'ISIN': '<ez-grid-column ez-i18n-title="valuation:isin" name="isin">{{solData.isin}}</ez-grid-column>',
      'MATURITY_DATE': '<ez-grid-column ez-i18n-title="valuation:maturityDate" ez-sort="solData.maturityDate | arrayToDate" type="date">{{solData.maturityDate | arrayToStrDateWithMonth}}</ez-grid-column>',
      'COB_DATE': '<ez-grid-column ez-i18n-title="valuation:valuationDate" ez-sort="solData.cobDate | arrayToDate" type="date">{{solData.cobDate | arrayToStrDateWithMonth}}</ez-grid-column>',
      'BID': '<ez-grid-column ez-i18n-title="valuation:bid" ez-sort="solData.bid" type="num">{{solData.bid | number:numberOfDecimal }}</ez-grid-column>',
      'ASK': '<ez-grid-column ez-i18n-title="valuation:ask" ez-sort="solData.ask" type="num">{{solData.ask | number:numberOfDecimal }}</ez-grid-column>',
      'PRICE_IN': '<ez-grid-column ez-i18n-title="valuation:priceIn">{{solData.priceIn ? "%" : ("valuation:currency" | i18next)}}</ez-grid-column>',
      'CURRENCY': '<ez-grid-column ez-i18n-title="valuation:currency">{{solData.currency}}</ez-grid-column>',
      'CUSIP': '<ez-grid-column ez-i18n-title="valuation:cusip">{{solData.cusip}}</ez-grid-column>',
      'UNIT': '<ez-grid-column ez-i18n-title="valuation:unit">{{solData.unit}}</ez-grid-column>',
      'STRIKE_DATE': '<ez-grid-column ez-i18n-title="valuation:strikeDate" ez-sort="solData.strikeDate | arrayToDate" type="date">{{solData.strikeDate | arrayToStrDateWithMonth}}</ez-grid-column>',
      'WRAPPER': '<ez-grid-column ez-i18n-title="valuation:wrapper">{{solData.wrapper}}</ez-grid-column>',
      'BLOOMBERG': '<ez-grid-column ez-i18n-title="valuation:bloomberg">{{solData.bloomberg}}</ez-grid-column>',
      'REUTERS': '<ez-grid-column ez-i18n-title="valuation:reuters">{{solData.reuters}}</ez-grid-column>',
      'ISSUER': '<ez-grid-column ez-i18n-title="valuation:issuer">{{solData.issuer}}</ez-grid-column>',
      'UNITARY_NOMINAL': '<ez-grid-column ez-i18n-title="valuation:unitaryNominal" ez-sort="solData.unitaryNominal" type="num">{{solData.unitaryNominal | number:numberOfDecimal }}</ez-grid-column>',
      'VALUATION_DATE': '<ez-grid-column ez-i18n-title="valuation:valuationDate" ez-sort="solData.valuationDate | arrayToDate" type="date">{{solData.valuationDate | arrayToStrDateWithMonth}}</ez-grid-column>',
      'ISSUE_DATE': '<ez-grid-column ez-i18n-title="valuation:issueDate" ez-sort="solData.issueDate | arrayToDate" type="date">{{solData.issueDate | arrayToStrDateWithMonth}}</ez-grid-column>',
      'NOMINAL_ISSUE': '<ez-grid-column ez-i18n-title="valuation:nominalIssue" ez-sort="solData.nominalIssue" type="num">{{solData.nominalIssue | number:numberOfDecimal }}</ez-grid-column>',
      'PRODUCT_TYPE': '<ez-grid-column ez-i18n-title="valuation:productType">{{solData.productType}}</ez-grid-column>',
      'MEDIA': '<ez-grid-column ez-i18n-title="valuation:media">{{solData.media}}</ez-grid-column>',
      'SPREAD': '<ez-grid-column ez-i18n-title="valuation:spread" ez-sort="solData.spread" type="num">{{solData.spread | number:numberOfDecimal }}</ez-grid-column>',
      'MAX_PERF_LABEL': '<ez-grid-column ez-i18n-title="valuation:maxPerfLabel">{{solData.maxPerfLabel}}</ez-grid-column>',
      'MAX_PERF': '<ez-grid-column ez-i18n-title="valuation:maxPerf" ez-sort="solData.maxPerf" type="num">{{solData.maxPerf | number:numberOfDecimal }}</ez-grid-column>',
      'MIN_PERF1_LABEL': '<ez-grid-column ez-i18n-title="valuation:minPerf1Label">{{solData.minPerf1Label}}</ez-grid-column>',
      'MIN_PERF1': '<ez-grid-column ez-i18n-title="valuation:minPerf1" ez-sort="solData.minPerf1" type="num">{{solData.minPerf1 | number:numberOfDecimal }}</ez-grid-column>',
      'MIN_PERF2_LABEL': '<ez-grid-column ez-i18n-title="valuation:minPerf2Label">{{solData.minPerf2Label}}</ez-grid-column>',
      'MIN_PERF2': '<ez-grid-column ez-i18n-title="valuation:minPerf2" ez-sort="solData.minPerf2" type="num">{{solData.minPerf2 | number:numberOfDecimal }}</ez-grid-column>',
      'OVERHEDGE': '<ez-grid-column ez-i18n-title="valuation:overhedge">{{solData.overhedge}}</ez-grid-column>',
      'ELS': '<ez-grid-column ez-i18n-title="valuation:els">{{solData.els}}</ez-grid-column>',
      'CLEAN_ASK': '<ez-grid-column ez-i18n-title="valuation:cleanAsk" ez-sort="solData.cleanAsk" type="num">{{solData.cleanAsk | number:numberOfDecimal }}</ez-grid-column>',
      'CONVERSE': '<ez-grid-column ez-i18n-title="valuation:converse">{{solData.converse}}</ez-grid-column>',
      'DIVIDEND_SWAP': '<ez-grid-column ez-i18n-title="valuation:dividendSwap" ez-sort="solData.dividendSwap" type="num">{{solData.dividendSwap | number:numberOfDecimal }}</ez-grid-column>',
      'IS_CLEAN_PRICE': '<ez-grid-column ez-i18n-title="valuation:isCleanPrice">{{solData.isCleanPrice}}</ez-grid-column>',
      'SPREAD_SA': '<ez-grid-column ez-i18n-title="valuation:spreadSa">{{solData.spreadSa}}</ez-grid-column>',
      'SPOT': '<ez-grid-column ez-i18n-title="valuation:spot" ez-sort="solData.spot" type="num">{{solData.spot | number:numberOfDecimal }}</ez-grid-column>',
      'CLEAN_BID': '<ez-grid-column ez-i18n-title="valuation:cleanBid" ez-sort="solData.cleanBid" type="num">{{solData.cleanBid | number:numberOfDecimal }}</ez-grid-column>',
      'AVG_PERF_LABEL': '<ez-grid-column ez-i18n-title="valuation:avgPerfLabel">{{solData.avgPerfLabel}}</ez-grid-column>',
      'EERA': '<ez-grid-column ez-i18n-title="valuation:eera" ez-sort="solData.eera" type="num">{{solData.eera | number:numberOfDecimal }}</ez-grid-column>',
      'RL': '<ez-grid-column ez-i18n-title="valuation:rl" ez-sort="solData.rl" type="num">{{solData.rl | number:numberOfDecimal }}</ez-grid-column>',
      'MID': '<ez-grid-column ez-i18n-title="valuation:mid" ez-sort="solData.mid" type="num">{{solData.mid | number:numberOfDecimal }}</ez-grid-column>',
      'ACCRUED_INTEREST': '<ez-grid-column ez-i18n-title="valuation:accruedInterest" ez-sort="solData.accruedInterest" type="num">{{solData.accruedInterest | number:numberOfDecimal }}</ez-grid-column>',
      'CLEAN_PRICE': '<ez-grid-column ez-i18n-title="valuation:cleanPrice" ez-sort="solData.cleanPrice" type="num">{{solData.cleanPrice | number:numberOfDecimal }}</ez-grid-column>',
      'EXPOSURE': '<ez-grid-column ez-i18n-title="valuation:exposure" ez-sort="solData.exposure" type="num">{{solData.exposure | number:numberOfDecimal }}</ez-grid-column>',
      'VOLATILITY': '<ez-grid-column ez-i18n-title="valuation:volatility" ez-sort="solData.volatility" type="num">{{solData.volatility | number:numberOfDecimal }}</ez-grid-column>',
      'CLEAN_MID': '<ez-grid-column ez-i18n-title="valuation:cleanMid" ez-sort="solData.cleanMid" type="num">{{solData.cleanMid | number:numberOfDecimal }}</ez-grid-column>',
      'RA': '<ez-grid-column ez-i18n-title="valuation:ra" ez-sort="solData.ra" type="num">{{solData.ra | number:numberOfDecimal }}</ez-grid-column>',
      'RATE_LEG': '<ez-grid-column ez-i18n-title="valuation:rateLeg" ez-sort="solData.rateLeg" type="num">{{solData.rateLeg | number:numberOfDecimal }}</ez-grid-column>',
      'SPOT_DATE': '<ez-grid-column ez-i18n-title="valuation:spotDate" ez-sort="solData.spotDate | arrayToDate" type="date">{{solData.spotDate | arrayToStrDateWithMonth}}</ez-grid-column>',
      'OPTION1': '<ez-grid-column ez-i18n-title="valuation:option1" ez-sort="solData.option1" type="num">{{solData.option1 | number:numberOfDecimal }}</ez-grid-column>',
      'OPTION1_LESSOVERHEDGE': '<ez-grid-column ez-i18n-title="valuation:option1LessOverhedge">{{solData.option1LessOverhedge}}</ez-grid-column>',
      'OPTION2': '<ez-grid-column ez-i18n-title="valuation:option2" ez-sort="solData.option2" type="num">{{solData.option2 | number:numberOfDecimal }}</ez-grid-column>',
      'RHO': '<ez-grid-column ez-i18n-title="valuation:rho" ez-sort="solData.rho" type="num">{{solData.rho | number:numberOfDecimal }}</ez-grid-column>',
      'DELTA': '<ez-grid-column ez-i18n-title="valuation:delta" ez-sort="solData.delta" type="num">{{solData.delta | number:numberOfDecimal }}</ez-grid-column>',
      'DELTA_NIKKEI': '<ez-grid-column ez-i18n-title="valuation:deltaNikkei" ez-sort="solData.deltaNikkei" type="num">{{solData.deltaNikkei | number:numberOfDecimal }}</ez-grid-column>',
      'VEGA': '<ez-grid-column ez-i18n-title="valuation:vega" ez-sort="solData.vega" type="num">{{solData.vega | number:numberOfDecimal }}</ez-grid-column>',
      'GAMMA': '<ez-grid-column ez-i18n-title="valuation:gamma" ez-sort="solData.gamma" type="num">{{solData.gamma | number:numberOfDecimal }}</ez-grid-column>',
      'UNDL_NAME': '<ez-grid-column ez-i18n-title="valuation:undlName">{{solData.undlName}}</ez-grid-column>',
      'UNDL_BLOOMBERG_CODE': '<ez-grid-column ez-i18n-title="valuation:undlBloombergCode">{{solData.undlBloombergCode}}</ez-grid-column>',
      'UNDL_REUTERS_CODE': '<ez-grid-column ez-i18n-title="valuation:undlReutersCode">{{solData.undlReutersCode}}</ez-grid-column>',
      'NAV': '<ez-grid-column ez-i18n-title="valuation:nav" ez-sort="solData.nav" type="num">{{solData.nav | number:numberOfDecimal }}</ez-grid-column>',
      'NAV_NB_UNIT': '<ez-grid-column ez-i18n-title="valuation:navNbUnit" ez-sort="solData.navNbUnit" type="num">{{solData.navNbUnit | number:numberOfDecimal }}</ez-grid-column>',
      'NAV_PER_UNIT': '<ez-grid-column ez-i18n-title="valuation:navPerUnit" ez-sort="solData.navPerUnit" type="num">{{solData.navPerUnit | number:numberOfDecimal }}</ez-grid-column>',
      'FUND_CALCULATION_DATE': '<ez-grid-column ez-i18n-title="valuation:fundCalculationDate" ez-sort="solData.fundCalculationDate | arrayToDate" type="date">{{solData.fundCalculationDate | arrayToStrDateWithMonth}}</ez-grid-column>',
      'OPEN_CLOSE': '<ez-grid-column ez-i18n-title="valuation:openClose">{{solData.openClose}}</ez-grid-column>',
      'OUTSTANDING_NOMINAL': '<ez-grid-column ez-i18n-title="valuation:outstandingNominal" ez-sort="solData.outstandingNominal" type="num">{{solData.outstandingNominal | number:numberOfDecimal }}</ez-grid-column>',
      'NOTIONAL': '<ez-grid-column ez-i18n-title="valuation:notional" ez-sort="solData.notional" type="num">{{solData.notional | number:numberOfDecimal }}</ez-grid-column>',
      'SALES_COMMENT': '<ez-grid-column ez-i18n-title="valuation:salesComment">{{solData.salesComment}}</ez-grid-column>',
      'TRADER_COMMENT': '<ez-grid-column ez-i18n-title="valuation:traderComment">{{solData.traderComment}}</ez-grid-column>'
    };
    /* eslint-enable */

    var titleRegex = /ez-i18n-title="valuation:.*?"/g;
    var aposRegex = /'/g;

    var chooseColumnForBreakpoint = function(columns, breakpoint) {
      if (breakpoint !== 'xs') {
        return columns;
      }
      var i = 0;
      var xsColumn = [];

      xsColumn[i++] = 'NAME';
      if (columns.indexOf('COB_DATE')) {
        xsColumn[i++] = 'COB_DATE';
      } else if (columns.indexOf('VALUATION_DATE')) {
        xsColumn[i++] = 'VALUATION_DATE';
      }
      if (columns.indexOf('ASK')) {
        xsColumn[i++] = 'ASK';
      } else if (columns.indexOf('BID')) {
        xsColumn[i++] = 'BID';
      } else if (columns.indexOf('MID')) {
        xsColumn[i++] = 'MID';
      }
      if (columns.indexOf('PRICE_IN')) {
        xsColumn[i++] = 'PRICE_IN';
      }
      return xsColumn;
    };

    return {
      restrict: 'EA',
      replace: true,
      template: '<div class="solListTable data-loading"></div>',
      scope: {
        solSubscription: '=',
        productData: '=',
        columnList: '=',
        columnTranslation: '=',
        numberOfDecimal: '='
      },
      controller: function($scope, $window) {
        // dataTable callback to save / store table state
        var keyDataTableLength = 'sol_dataTable_length';
        $scope.dataTableLoadState = function(settings) {
          var previousValue = $window.localStorage[keyDataTableLength];
          if (previousValue !== undefined) {
            settings._iDisplayLength = parseInt(previousValue, 10);
          }
          return settings;
        };
        $scope.dataTableSaveState = function(settings, data) {
          if (data !== undefined && data.length !== undefined) {
            $window.localStorage.setItem(keyDataTableLength, data.length);
          }
        };
      },
      link: function($scope, $element) {
        // Click on a table row ( /!\ productId.type is optional)
        $scope.openSolProduct = function(product) {
          $location.path('/valuation/sol/' + $scope.solSubscription + '/' + product.productId.source +
            (product.productId.type ? '/' + product.productId.type + '/' : '/') + product.productId.code);
        };

        $scope.$watch('columnList', function(newValue) {
          // Remove old inner html
          $element.children().remove();
          var html = null;
          if (newValue) {
            // Calculate which ez-grid-column to display
            var columnToUsed = chooseColumnForBreakpoint($scope.columnList, $scope.$parent.ezweb.responsive.breakpoint);

            // Generate the html to append
            html = tableHeader;
            for (var i = 0; i < columnToUsed.length; i++) {
              var colName = columnToUsed[i];
              if (colName in tableOfTemplate) {
                var columnHtml = tableOfTemplate[colName];
                // Check if a specific translation have been given for that column
                if ($scope.columnTranslation !== undefined) {
                  var translation = $scope.columnTranslation[colName];
                  if (translation !== undefined) {
                    columnHtml = columnHtml.replace(titleRegex, 'title="' + translation.replace(aposRegex, '’') + '"');
                  }
                }
                html += columnHtml;
              }
            }
            html += tableFooter;
          } else {
            // "remove" loading when user hasn't sol view
            html = '<p class="solListEmpty">{{"ezweb.grid:emptyTable" | i18next}}</p>';
          }
          // Insert new calculated html
          $element.append(html);
          $compile($element.contents())($scope);
        }, true);

        $element.on('$destroy', function() {
          $element.children().remove();
        });
      }
    };
  });
