angular.module('app')
  .constant('informationConstants', {
    infoTimesOptions: (function() {
      var timesOptions = [];
      for (var i = 0; i < 24; i++) {
        var hh = i < 10 ? '0' + i : String(i);
        var hh00 = hh + ':00';
        var hh30 = hh + ':30';
        timesOptions[hh00] = hh00;
        timesOptions[hh30] = hh30;
      }
      return timesOptions;
    })()
  })
  .config(function($routeProvider) {
    $routeProvider.when('/applicationInfo', {
      templateUrl: 'applicationInfo.html'
    });
  })
  .controller('InformationController', function($scope, $filter, $i18next, appInformation, informationConstants) {
    // Init IHM elements
    $scope.displayBox = {
      Display: false,
      Items: ['Display']
    };
    $scope.rangeOfDate = [null, null];
    $scope.times = informationConstants.infoTimesOptions;
    $scope.textType = {
      Type: 'Default',
      Items: ['Default', 'Custom']
    };
    $scope.defaultText = {
      value: 'defaultText1',
      Items: {defaultText1: $i18next('information:defaultText1')}
    };

    // Get the last info if exists
    appInformation.get(function(lastInfo) {
      $scope.displayBox.Display = lastInfo.display;
      $scope.textType.Type = lastInfo.textType === 'Custom' ? 'Custom' : 'Default';
      $scope.defaultText.value = lastInfo.textType === 'Default' ? lastInfo.text : '';
      $scope.customText = lastInfo.textType === 'Custom' ? lastInfo.text : '';
      if (lastInfo.startDateTime || lastInfo.endDateTime) {
        $scope.rangeOfDate = [
          $filter('arrayToDateTime')(lastInfo.startDateTime),
          $filter('arrayToDateTime')(lastInfo.endDateTime)
        ];
        $scope.startTime = $filter('date')($scope.rangeOfDate[0], 'HH:mm');
        $scope.endTime = $filter('date')($scope.rangeOfDate[1], 'HH:mm');
      }
    });

    $scope.send = function() {
      var info = {
        display: $scope.displayBox.Display,
        startDateTime: $filter('date')($scope.rangeOfDate[0], 'yyyy-MM-dd') + 'T' + $scope.startTime,
        endDateTime: $filter('date')($scope.rangeOfDate[1], 'yyyy-MM-dd') + 'T' + $scope.endTime,
        text: $scope.textType.Type === 'Default' ? $scope.defaultText.value : $scope.customText
      };

      appInformation.save(info,
        function() {
          $scope.feedBackSuccess = true;
          $scope.feedBackError = false;
        },
        function() {
          $scope.feedBackError = true;
          $scope.feedBackSuccess = false;
        }
      );
    };
  });
