angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/sales', {
      templateUrl: 'sales.html'
    });
  })
  .controller('SalesController', function($scope, gAnalytics, salesFind) {
    gAnalytics.viewPage('/sales', 'Sales');

    $scope.salesCommercialClients = salesFind.query();
  });
