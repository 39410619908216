angular.module('app')
  .factory('valuationService', function($resource) {
    return {
      deals: function() {
        return $resource('mvc/deals/:assetId/:dealId', {}, {});
      },
      userCurrencies: function() {
        return $resource('mvc/deals/userCurrencies', {}, {});
      }
    };
  })
.factory('crossAmountService', function($resource) {
  return $resource('mvc/deals/crossAmount', {}, {
    findCrossAmount: {method: 'POST'}
  });
});
