angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/home', {
      templateUrl: 'home.html',
      layout: 'welcomeLayout'
    })
      .otherwise({
        redirectTo: '/home'
      });
  })
  .controller('HomeController', function($scope, $location, $anchorScroll, $uibModal , gAnalytics, supportUsers,
                                         disclaimer) {
    gAnalytics.viewPage('/home', 'Home');

    var pc = this;
  
    pc.openDfaDisclaimer = function () {
                
      if(!$scope.ezweb.user.dfaDisclaimerOpened) {
        var modalInstance = $uibModal.open({
          animation: true,
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          templateUrl: 'dfa-disclaimer/dfa-disclaimer.html',
          controller: 'DfaDisclaimer',
          backdrop: 'static',
          controllerAs: 'pc'
        });
        $scope.ezweb.user.dfaDisclaimerOpened = true;
      }
      
    };

    pc.openMainDisclaimerModal = function () {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'main-disclaimer/main-disclaimer.html',
        controller: 'MainDisclaimer',
        backdrop: 'static',
          controllerAs: 'pc'
      });

      modalInstance.result.then(function () {
        pc.openDfaDisclaimer();
      });
    };

    var listener = $scope.$watch('ezweb.user.connected', function(connected, value) {
      if (connected) {
        if ($scope.ezweb.user.disclaimerValidated) {
          pc.openDfaDisclaimer();
        } else {
          pc.openMainDisclaimerModal();
        }        
      }
    });
  });
