var layoutName = 'welcomeLayout';
angular.module('app')
  .config(function(ezLayoutProvider) {
    ezLayoutProvider.register(layoutName, {templateUrl: 'welcome.html'});
  })
  .controller('WelcomeController', function($scope, $location) {
    $scope.layoutHeader = {templateUrl: '../common/html/header.html'};
    $scope.layoutFooter = {templateUrl: '../footer/footer.html'};
    $scope.infoCookies = {templateUrl: '../common/html/infoCookies.html'};
    $scope.layoutLogin = {templateUrl: '../common/html/login.html'};

    $scope.authenticate = function() {
      $location.path('/dashboard');
    };
  });
