angular.module('app')
  .factory('adminGroups', function($resource) {
    return $resource('mvc/admin/group', {}, {});
  })
  .factory('adminUsers', function($resource) {
    return $resource('mvc/admin/user/:login', {}, {});
  })  
  .factory('adminRemoveGroup', function($resource) {
    return $resource('mvc/admin/group/:groupName/remove', {}, {});
  });
