angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/admin/editUser', {
      templateUrl: 'createUser.html'
    });
  })
  .controller('CreateUserController', function($scope, $routeParams, gAnalytics, adminUsers, adminGroups) {
    gAnalytics.viewPage('/admin/create', 'Create user');

    adminGroups.query({}, function(groupFromService) {
      var groupNames = [];
      groupFromService.forEach(function(group) {
        groupNames.push(group.name);
      });
      $scope.groupsData = groupNames;
    });

    if ($routeParams.user) {
      adminUsers.get({login: $routeParams.user}, function(userFromService) {
        var groups = {};
        userFromService.groupNames.forEach(function(val) {
          groups[val] = true;
        });
        $scope.user = {
          login: userFromService.username,
          group: groups,
          salesBdrInitial: userFromService.salesBdrInitial ? userFromService.salesBdrInitial.join() : '',
          salesDeskId: userFromService.salesDeskId
        };
        $scope.isCreation = false;
      });
    } else {
      // if a user doesn't exist then login text field and group checkboxes will be empty
      $scope.user = {login: null, group: {}};
      $scope.isCreation = true;
    }

    // create or update a user
    $scope.createOrUpdate = function() {
      $scope.user.login = $scope.user.login.toLowerCase();
      adminUsers.save($scope.user,
        function() {
          $scope.isSaveOk = true;
          $scope.saveMessage = $scope.isCreation ? 'admin:messageCreateInfo' : 'admin:messageEditInfo';
        },
        function() {
          $scope.isSaveOk = false;
          $scope.saveMessage = $scope.isCreation ? 'admin:messageCreateError' : 'admin:messageEditError';
        }
      );
    };


    // delete a user
    $scope.deleteUser = function() {
      if(!confirm("Do you want to delete user ?")) {
        return;
      }

      $scope.user.login = $scope.user.login.toLowerCase();      
      adminUsers.delete({login: $scope.user.login},
        function() {
          $scope.saveMessage = 'admin:userDeletedInfo';
        },
        function() {
          $scope.saveMessage = 'admin:userDeletedError';
        }
      );
    };
  });
