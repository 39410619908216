FooterController.$inject = [];
function FooterController(
  $http,
  ezAppScope,
  ezUsers,
  ezAnalytics
) {
  var en =
    "'en':[{'label': 'Notice to Investors', 'value': 'http://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_TO_INVESTORS'},{'label': 'Valuation Policy', 'value': 'https://valuation.sgmarkets.com/resources/valuation-policy.pdf'}]";
  var fr =
    "'fr':[{'label': 'Notice à l‘Attention des Investisseurs', 'value': 'http://docs.sgcib.com/Content/Resource/Key/SGM_LEGAL_NOTICE_TO_INVESTORS'},{'label': 'Politique de valorisation', 'value': 'https://valuation.sgmarkets.com/resources/valuation-policy-fr.pdf'}]";
  this.legalNotices = "{" + en + "," + fr + "}";

  var self = this;
  self.$onInit = function () {
    var widget = document.querySelector("sgwt-mini-footer");

    $http.get("mvc/support/env").then(function (response) {
      var env = response.data;
      ezAppScope.ensure("envconfig", env);

      ezUsers.get(function () {
        // set cookies on app initialization
        widget.getUserConsentOnMatomo().then(function (consent) {
          updateMatomoUserAcceptance(consent);
          startPiwik(env.piwikurl, env.siteid);
        });
      });
      // end setup for piwik
    });

    // set cookies on user action
    widget.addEventListener(
      "sgwt-mini-footer--user-consent-changed",
      function () {
        // You get all the details of ALL user consents from the event detail property:
        // You can also check the consent through our helper functions:
        widget.getUserConsentOnMatomo().then(function (consent) {
          updateMatomoUserAcceptance(consent);
        });
      }
    );
  };

  var updateMatomoUserAcceptance = function (consent) {
    if (consent) {
      window._paq.push(["rememberCookieConsentGiven", 4320]); //4320 = retention period of the cookie for 6 month
      window._paq.push(["setCustomDimension", 10, true]);
    } else {
      window._paq.push(["setCustomDimension", 10, false]);
      window._paq.push(["forgetCookieConsentGiven"]);
    }
  };

  var startPiwik = function (piwikUrl, siteId) {
    if(!ezAnalytics.check()) {
      window._paq = window._paq || [];
      // Clean Matomo cookies
      window._paq.push(["forgetCookieConsentGiven"]);
      // Ask Matomo not to use cookies
      window._paq.push(["requireCookieConsent"]); // That must be track before the trackPageView.
      window._paq.push(["trackPageView"]);
      window._paq.push(["setUserId", ezUsers.username]);
      ezAnalytics.start(piwikUrl, siteId);
    }
  };
}

angular.module("app").component("appFooter", {
  templateUrl: "footer.html",
  bindings: {},
  controller: [
    "$http",
    "ezAppScope",
    "ezUsers",
    "ezAnalytics",
    FooterController,
  ],
  controllerAs: "footerCtrl",
});
