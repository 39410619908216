angular.module('app')
  .factory('salesClients', function($http) {
    var cachedResult = null;
    var getCache = function() {
      return cachedResult;
    };
    var resetCache = function() {
      cachedResult = null;
    };
    var getSalesClients = function(callback) {
      $http.get('mvc/sales/findSalesClients')
        .then(function(response) {
          cachedResult = response.data;
          callback(response.data);
        }, function() {});
    };
    return {
      query: getSalesClients,
      getCache: getCache,
      resetCache: resetCache
    };
  })
  .factory('salesClientDeals', function($resource) {
    return $resource('mvc/sales/findSalesClientsDeals', {}, {
      findSalesClientsDealsList: {method: 'POST', isArray: true}
    });
  })
  .factory('salesClientDeal', function($resource) {
    return $resource('mvc/sales/findSalesClientsDeal/:dealId', {}, {});
  });
