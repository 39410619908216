angular.module('app')
  .directive('equitydetails', function() {
    // constants
    var URL_EXCEL_EQ = 'exportDealDetails/Equity';
    var URL_EXCEL_SD = 'exportSalesDashboardDealDetails';
    var FROM_EQUITY = 'equity';
    var FROM_SD = 'salesDashboard';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'equitydetails.html',
      scope: {
        dealData: '=',
        from: '@',
        currencies: '=',
        currencyCallback: '&'
      },
      controller: function($scope, gAnalytics, $i18next, $rootScope) {
        $scope.languageSelected = $i18next.options.lng;

        // watch on language change because of isolated scope
        $rootScope.$on('i18nextLanguageChange', function(event, language) {
          $scope.languageSelected = language;
        });

        // url export excel valorisation
        $scope.exportExcel = URL_EXCEL_SD;
        if ($scope.from === FROM_EQUITY) {
          $scope.exportExcel = URL_EXCEL_EQ;
        }

        $scope.logDlDoc = function() {
          if ($scope.from === FROM_EQUITY) {
            gAnalytics.click('XLS Detail Equity');
          } else {
            gAnalytics.click('XLS Detail Equity SalesDashboard');
          }
        };

        $scope.changeCurrency = function(currency) {
          // need to pass an object to the callback if not, it does not work
          $scope.currencyCallback({currency: currency});
        };

        $scope.showValue = function(value) {
          return ($scope.from === FROM_SD) || value;
        };
      }
    };
  });
