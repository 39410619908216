angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/support', {
      templateUrl: 'support.html'
    });
  })
  .controller('SupportController', function($scope, $rootScope, gAnalytics, ezAppScope, supportUsers, versioning, salesClients) {
    gAnalytics.viewPage('/support', 'Support');

    // $scope.version = versioning.get();

    $scope.searchInputContact = '';
    $scope.contactsDatas = [];

    $scope.search = function() {
      supportUsers.search($scope.searchInputContact, function(data) {
        $scope.contactsDatas = data;
      });
    };

    $scope.goTo = function(contact) {
      salesClients.resetCache();
      supportUsers.select(contact.username, function(selectedContact) {
        supportUsers.getInfo(function(infos) {
          $rootScope.$broadcast('NavigateAsInfo', infos, $scope.ezweb.user.roles);
        });
        ezAppScope.ensure('client', {selected: selectedContact.username});
      });
    };
  });
