window.ezweb.start() //
  // Configure the different aspects of your app here.
  // We give you an example using the EZ-Web default authentication backend and Spring Security
  .config(function(ezUsersProvider, ezPostAuthenticationBackendProvider) {
    // Always configure the backend you are going to use before
    // configuring the user service with it (if configuration of the backend is necessary).
    ezPostAuthenticationBackendProvider.configure({
      // See the file (hopefully soon the docs) to see what options are available.
      // Any option not set is kept at default value.
      supportsRememberMe: false,
      currentUserUri: 'mvc/authentication/current'
    });
    ezUsersProvider.configure('ezPostAuthenticationBackend');
  })
  // Redirect when 403 forbidden
  .config(function($httpProvider) {
    $httpProvider.interceptors.push(function($q, $window) {
      return {
        responseError: function(response) {
          var status = response.status;
          if (status === 403 && response.config.method !== 'PUT') {
            // the actual session has expired. we redirect the user to the login form.
            $window.location.reload();
          }
          return $q.reject(response);
        }
      };
    });
  })
  .run(function($http, ezAppScope, $window) {
    // Get the ENV to store it in appScope
    $http.get('mvc/application/config').then(function(response) {
      ezAppScope.ensure('envconfig', response.data);
      if (response.data.selectedUsername) {
        ezAppScope.ensure('client', {selected: response.data.selectedUsername});
      }
    });
    // Save the type of authentication to appScope
    ezAppScope.ensure('authentification', {
      type: $window.app.authentificationType.toUpperCase(),
      logoutUri: '?action=logout'
    });
  });
