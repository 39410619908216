angular.module('app')
  .directive('equitygrid', function() {
    // constants
    var URL_EXCEL_EQ = 'exportListOfDeal/Equity';
    var URL_EXCEL_SD = 'exportSalesDashboardDeals';
    var EXPORT_XLS_CSS_EQ = 'col-xs-6 col-md-3 col-md-offset-0 col-lg-offset-1 cliquable';
    var EXPORT_XLS_CSS_SD = 'col-xs-6 col-md-3 col-md-offset-0 cliquable';
    var FROM_EQUITY = 'equity';
    var FROM_SALES_DB = 'salesDashboard';

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'equitygrid.html',
      scope: {
        dealsDatas: '=',
        showCurrency: '=',
        currencyCallback: '&',
        dealCallback: '&',
        from: '@'
      },
      controller: function($scope, $filter, gAnalytics, $i18next, $rootScope, $window) {
        $scope.languageSelected = $i18next.options.lng;
        $scope.userCurrencyDealEquity = $rootScope.app.currency || '';

        // watch on language change because of isolated scope
        $rootScope.$on('i18nextLanguageChange', function(event, language) {
          $scope.languageSelected = language;
        });

        // url export excel valorisation + css
        $scope.exportExcel = URL_EXCEL_SD;
        $scope.exportXlsCss = EXPORT_XLS_CSS_SD;
        if ($scope.from === FROM_EQUITY) {
          $scope.exportExcel = URL_EXCEL_EQ;
          $scope.exportXlsCss = EXPORT_XLS_CSS_EQ;
        }
        if ($scope.from === FROM_SALES_DB) {
          $scope.fromSalesDb = true;
        } else {
          $scope.fromSalesDb = false;
        }

        // function used to properly sort the column NotionalAmountQuantity with dataTable
        $scope.sortNotionalAmountQuantity = function(equityData) {
          return (equityData.productTypetoView === 'Option' || equityData.productTypetoView === 'ELS') ?
            equityData.quantity : equityData.notionalAmount.value;
        };

        // function used to properly display the column NotionalAmountQuantity with dataTable
        $scope.displayNotionalAmountQuantity = function(equityData) {
          return (equityData.productTypetoView === 'Option' || equityData.productTypetoView === 'ELS') ?
            ($filter('number')(equityData.quantity, 2)) :
            (($filter('number')(equityData.notionalAmount.value, 2)) + ' ' + equityData.notionalAmount.currency);
        };

        // switch the currency for valuation
        $scope.changeCurrency = function(currency) {
          $rootScope.app.currency = currency.name;
          // need to pass an object to the callback if not, it does not work
          $scope.currencyCallback({currency: currency});
        };

        // open the details of a deal
        $scope.openEquityDeal = function(deal) {
          // need to pass an object to the callback if not, it does not work
          $scope.dealCallback({tradeId: deal.tradeId});
        };

        // dataTable callback to save / store table state
        var keyDataTableLength = 'equity_dataTable_length_' + $scope.from;
        $scope.dataTableLoadState = function(settings) {
          var previousValue = $window.localStorage[keyDataTableLength];
          if (previousValue !== undefined) {
            settings._iDisplayLength = parseInt(previousValue, 10);
          }
          return settings;
        };
        $scope.dataTableSaveState = function(settings, data) {
          if (data !== undefined && data.length !== undefined) {
            $window.localStorage.setItem(keyDataTableLength, data.length);
          }
        };

        $scope.logDlDoc = function() {
          if ($scope.from === FROM_EQUITY) {
            gAnalytics.click('XLS List Equity');
          } else {
            gAnalytics.click('XLS List Equity SalesDashboard');
          }
        };

        $scope.getClass = function(equityData) {
          if (!$scope.fromSalesDb) {
            return '';
          }

          return equityData.visibility ? 'deal deal-published' : 'deal deal-not-published';
        };

        $scope.homogeonousCurrencies = function(mtmData) {
          if (!$scope.fromSalesDb) {
            return false;
          }
          if (mtmData === undefined || mtmData === null || mtmData[0] === undefined || mtmData[0] === null) {
            return false;
          }
          var anyCurrency = mtmData[0].currency;
          for (var i = 0; i < mtmData.length; i++) {
            if (mtmData[i] === null || mtmData[i] === undefined || mtmData[i].currency !== anyCurrency) {
              return false;
            }
          }
          return true;
        };
      }
    };
  });
