angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/stp/:productSource/:productType/:productCode', {
      templateUrl: 'stpDetails.html'
    }).when('/stp/:productSource/:productCode', {
      templateUrl: 'stpDetails.html'
    });
  })
  .controller('StpDetailController', function($scope, $window, $routeParams, gAnalytics, stpProducts) {
    gAnalytics.viewPage('/stpDetails', 'Stp');
    var self = this;
    var prdCode = {
      source: $routeParams.productSource,
      type: $routeParams.productType,
      code: $routeParams.productCode
    };

    self.productData = stpProducts.productDetails.get(prdCode, function(prdData) {
      if (prdData.withPerformances) {
        self.productPerfs = stpProducts.productPerformance.query(prdCode);
      }
      if (prdData.withGraphic) {
        self.histoGraphData = stpProducts.chart.get(prdCode);
      }
    });

    self.loadCaa = function() {
      if (self.productData.withCorparateAction && !self.caApplications) {
        self.caApplications = stpProducts.cAAEvent.query(prdCode);
      }
    };

    self.loadUnderlyingPrices = function() {
      if (self.productData.withUnderlyings) {
        stpProducts.loadProductsUnderlyingPrices.save();
      }
    };
  });
