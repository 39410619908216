angular.module('app')
  .config(function($routeProvider) {
    $routeProvider.when('/dashboard', {
      templateUrl: 'dashboard.html'
    });
  })
  .controller('DashboardController', function($scope, $location, gAnalytics) {
    gAnalytics.viewPage('/dashboard', 'Dashboard');

    if ($scope.ezweb.user.roles.indexOf('CONSULT_SALES_DASHBOARD') > -1) {
      $location.path('/salesdashboard-equity');
    }

    $scope.$on('NavigateAsInfo', function(event, infos) {
      if (infos.assets) {
        $location.path('/valuation/' + infos.assets[0]);
      }
    });
  });
