angular.module('app')
  .directive('decimalSelect', function() {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'decimalSelect.html',
      scope: {
        model: '=',
        key: '@',
        additionalClass: '@'
      },
      controller: function($scope, $window, ezAppScope) {
        var previousValue = $window.localStorage[$scope.key];
        if (previousValue) {
          $scope.model = previousValue;
          ezAppScope.ensure('decimal', {value: previousValue});
        } else {
          $scope.model = 2;
          ezAppScope.ensure('decimal', {value: 2});
        }

        $scope.$watch('model', function(after, before) {
          if (after !== before) {
            $window.localStorage.setItem($scope.key, after);
            ezAppScope.ensure('decimal', {value: after});
          }
        });
      }
    };
  });
